import {API} from "../../utils/API";
import * as signalR from "@microsoft/signalr";
import {store} from "../store";
import {fetchDataRequest, fetchDataSuccess, setNetworkErrorMessage} from "./index";
import {ACTIONS_NAME} from "../constants";
import {errorHandler} from "../../utils/utils";
import {NOTIFICATION_STATUS} from "../../utils/Constants";

export const getNotifications = (navigate, signIn, signOut, notificationsCount) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(`${API.NOTIFICATIONS}?pagesize=${notificationsCount}&IsRead=false`)
            .then((response) => {
                const newData = response?.data?.map((obj) => ({
                    ...obj,
                    statusEnum: NOTIFICATION_STATUS[obj.status]
                }));
                newData.sort((a, b) => a.statusEnum - b.statusEnum);
                return dispatch(fetchDataSuccess(newData, ACTIONS_NAME.NOTIFICATIONS));
            })
            .catch((error) => {
                errorHandler(error, navigate, dispatch, signIn, signOut, "notifications");
            });
    };
};

export const getNotificationsCount = (navigate, signIn, signOut) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        return API.get(`${API.NOTIFICATIONS}/count`)
            .then((response) => {
                return dispatch(fetchDataSuccess(response?.data, ACTIONS_NAME.NOTIFICATIONS_COUNT));
            })
            .catch((error) => {
                errorHandler(error, navigate, dispatch, signIn, signOut, "notifications");
            });
    };
};
export const getNotificationsFromSignalR = async (dispatch) => {
    try {
        const response = await API.get(`/negotiate`)
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(response?.data?.url, {
                accessTokenFactory: () => response.data?.accessToken
            })
            .build();
        connection.on('newMessage', (messageReceived) => {
            const notificationsList = store.getState().baseReducer.notifications;
            if (notificationsList) {
                const updatedNotificationsList = [JSON.parse(messageReceived.Text), ...notificationsList];
                const countObject = {
                    count: updatedNotificationsList?.length
                }
                dispatch(fetchDataSuccess(updatedNotificationsList, ACTIONS_NAME.NOTIFICATIONS));
                dispatch(fetchDataSuccess(countObject, ACTIONS_NAME.NOTIFICATIONS_COUNT));
            }
        });

        await connection.start();
    } catch (error) {
        dispatch(
            setNetworkErrorMessage({
                errorMessage: error.message
            })
        );
    }
}