import React, {useEffect, useState, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserContext} from '../../AuthorizedPage/UserProvider';
import styles from "./flexibledemandsummary.module.css";
import crossIcon from "../../../assets/Icons/close.svg";
import {useNavigate} from "react-router-dom";
import FlexibleDemandDetails from "../flexible-demand-details/FlexibleDemandDetails";
import plusIcon from "../../../assets/Icons/Plus.svg";
import {
    clearEditFlexibleDemand,
    clearFlexibleDemand,
    getFlexibleEditOrderDetails,
    setFlexibleDemandFlag,
    clickedIndex
} from "../../../store/actions";
import {API} from "../../../utils/API";
import {ORDER_TYPE, CONFIRMATION_PAGE, HOME_ROUTE} from "../../../utils/RoutesConstants";
import Loading from "../../../components/loading/Loading";
import {errorHandler} from "../../../utils/utils";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import {createCetDateObject, dateToUTC} from "../../../utils/date-utils";
import {FLEXIBLE, HEADER_ADD_FLEXIBLE_DEMAND, HEADER_EDIT_FLEXIBLE_DEMAND} from "../../Order/constants";
import HeaderTitle from "../../page-Header/HeaderTitle";
import {Box} from "@mui/material";

const FlexibleDemandSummary = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const orderId = useSelector((state) => state.orderReducer.editOrderId);
    const dispatch = useDispatch();
    const {signIn, signOut} = useContext(UserContext);
    const flexibleDemandArray = useSelector(
        (state) => state.orderReducer.flexibleDemand
    );
    const navigate = useNavigate();
    useEffect(() => {
        if (!flexibleDemandArray.length) {
            navigate(HOME_ROUTE);
        }
    }, [navigate, flexibleDemandArray]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const goToConfirmation = async () => {
        setLoading(true);
        try {
            if (orderId && flexibleDemandArray.length === 1) {
                const startDateTime = createCetDateObject(flexibleDemandArray[0].startDate, flexibleDemandArray[0].startHour)
                const endDateTime = createCetDateObject(flexibleDemandArray[0].endDate, flexibleDemandArray[0].endHour)
                const editDemandBody = {
                    demandType: ORDER_TYPE.TRAILER_BAY,
                    maxPrice: 100,
                    startOffTakeTime: dateToUTC(startDateTime),
                    endOffTakeTime: dateToUTC(endDateTime),
                    hydrogenDemand: parseInt(flexibleDemandArray[0].exitMass) - parseInt(flexibleDemandArray[0].entryMass),
                    companyId: flexibleDemandArray[0].companyId,
                    trailerId: flexibleDemandArray[0].id,
                    trailerDemandEntryAmount: parseInt(flexibleDemandArray[0].entryMass),
                    trailerDemandExitAmount: parseInt(flexibleDemandArray[0].exitMass)
                };
                await API.put(`${API.ORDERS_BASE_URL}/${orderId}`, editDemandBody);
            } else {
                const requestBody = [];
                for (let demand of flexibleDemandArray) {
                    const startDateTime = createCetDateObject(demand.startDate, demand.startHour)
                    const endDateTime = createCetDateObject(demand.endDate, demand.endHour)
                    const data = {
                        demandType: ORDER_TYPE.TRAILER_BAY,
                        maxPrice: 100,
                        startOfftakeTime: dateToUTC(startDateTime),
                        endOfftakeTime: dateToUTC(endDateTime),
                        hydrogenDemand: parseInt(demand.exitMass) - parseInt(demand.entryMass),
                        companyId: demand.companyId,
                        trailerId: demand.id,
                        trailerDemandEntryAmount: parseInt(demand.entryMass),
                        trailerDemandExitAmount: parseInt(demand.exitMass)
                    };
                    requestBody.push(data);
                }
                await API.post(API.BULK_ORDER_BASE_URL, requestBody);
            }
            setLoading(false);
            navigate(CONFIRMATION_PAGE);
        } catch (error) {
            setError(error);
            errorHandler(error, navigate, dispatch, signIn, signOut, 'flexibleDemandSummary');
        }
    };
    const clearOrders = () => {
        dispatch(clearFlexibleDemand());
        dispatch(clearEditFlexibleDemand());
        navigate(HOME_ROUTE);
    };

    if (loading) {
        return <Loading loadingText="Scheduling..."/>;
    }
    return (
        <div className={styles.container}>
            <div className={styles.summary}>
                <div className={styles.summary_header}>
                    <HeaderTitle title={orderId ? HEADER_EDIT_FLEXIBLE_DEMAND : HEADER_ADD_FLEXIBLE_DEMAND}
                                 titleWithoutButton={true}/>
                    <Box onClick={handleOpen}>
                        <img
                            src={crossIcon}
                            alt="crossIcon"
                            data-testid="crossbtn"
                        />
                    </Box>
                </div>
                <div className={styles.summary_content}>
                    <p>
                        {FLEXIBLE.SUMMARY_HEADER_P1}
                    </p>
                    <p>
                        {FLEXIBLE.SUMMARY_HEADER_P2}
                    </p>
                </div>
                <div className={styles.summaryCards}>
                    <FlexibleDemandDetails showAll={true}/>
                    {!orderId && (
                        <div className={styles.flexibleDemand_addBtn}>
                            <button onClick={() => {
                                dispatch(clearEditFlexibleDemand());
                                navigate(-1)
                            }}
                                    data-testid="addMoreDemand">
                                <img src={plusIcon} alt="plusIcon"/>
                                {' '}
                                Add More Demand
                            </button>
                        </div>
                    )}
                </div>
                <div className={styles.nextWork_Error}>{error}</div>
                <div className={styles.flexibleDemand_btn}>
                    <button
                        className={`${styles.btn} ${styles.backBtn}`}
                        onClick={() => {
                            dispatch(clickedIndex(flexibleDemandArray.length - 1));
                            dispatch(setFlexibleDemandFlag(true));
                            dispatch(getFlexibleEditOrderDetails({
                                ...flexibleDemandArray[flexibleDemandArray.length - 1],
                                orderId: orderId
                            }))
                            navigate(-1)
                        }}
                        data-testid="back"
                    >
                        Back
                    </button>
                    <button
                        className={`${styles.btn} ${styles.orderBtn}`}
                        onClick={goToConfirmation}
                        data-testid="add"
                    >
                        {orderId ? FLEXIBLE.BTN_UPDATE : FLEXIBLE.BTN_ADD}
                    </button>
                </div>
                <ConfirmationModal
                    open={open}
                    handleClose={handleClose}
                    handleAction={clearOrders}
                    textToShow={FLEXIBLE.CONFIRM_MODAL.MESSAGE_FLEXIBLE_SUMMARY}
                    firstBtnText={FLEXIBLE.CONFIRM_MODAL.CANCEL_BTN}
                    secondBtnText={FLEXIBLE.CONFIRM_MODAL.BTN_BACK_TO_MENU}
                />
            </div>
        </div>
    );
};

export default FlexibleDemandSummary;

