import {
    DEMAND_UNIT_OPTIONS_VALUE,
    FLEXIBLE_DEMAND_MINIMUM_LIMIT_ERROR,
    ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE,
    REQUESTED_HYDROGEN_AMOUNT_IS_LESS,
    SAME_DATE_ERROR,
    ORDER_LIMIT_ERROR_TRAILER_BAY
} from "../pages/Order/constants";
import {
    convertDateStrToDateObj,
    dateIsAfter,
    createCetDateObject,
    dateIsSameOrAfter,
    formatDateNoUTC,
    getMaxDays,
} from "../utils/date-utils";
import {
    dateValidationForFlexibleDemand,
    isFLCapGreaterThanDemandML,
    isFLCapLessDemandML,
    isNotFLCapGreaterThanMaxDemand,
    isNotFLCapLessMinDemand,
    timeValidationForFlexibleDemand,
    timeValidationForIndustrialOfftake,
    timeValidationForIndustrialOfftakeMaxDays,
    timeValidationForFlexibleDemandMaxDays, isFLEntryMasGreaterExistMass
} from "../utils/utils";
import yup from "./base-validations";

export const orderSchema = yup.object().shape({
    id: yup.string().test("id-required-present", "Enter ID.", async (value) => {
        return !!(typeof value === "undefined" || value);
    }),
    startDate: yup
        .string()
        .required("Please select a start date.")
        .test(
            "date-after",
            "Start date can not be after end date.",
            async (value, context) => {
                const startDate = convertDateStrToDateObj(value);
                const endDate = convertDateStrToDateObj(context.parent.endDate);
                return !dateIsAfter(startDate, endDate);
            }
        ),
    endDate: yup
        .string()
        .required("Please select a end date.")
        .test(
            "min-end-date",
            "End date must be greater than start date",
            async (value, context) => {
                const startDate = convertDateStrToDateObj(context.parent.startDate);
                const endDate = convertDateStrToDateObj(value);
                if (context.parent.startDate === "") return true;
                return dateIsSameOrAfter(endDate, startDate)
            }
        )
        .test(
            "min-end-date",
            ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE,
            async (value, context) => {
                const startDate = convertDateStrToDateObj(context.parent.startDate);
                const endDate = convertDateStrToDateObj(value);
                if (context.parent.startDate === "") return true;
                return getMaxDays(startDate, 30) >= convertDateStrToDateObj(endDate);
            }
        )
        .test("min-end-date", "", function (value, context) {
            const {path, parent, createError} = context;
            const startDate = convertDateStrToDateObj(parent.startDate);
            const endDate = convertDateStrToDateObj(value);
            if (parent.startDate === "") return true;
            return dateValidationForFlexibleDemand(
                JSON.parse(parent.isFlexibleDemand),
                startDate,
                endDate
            )
                ? createError({
                    path,
                    message: ORDER_LIMIT_ERROR_TRAILER_BAY,
                })
                : {};
        }),
    startHour: yup
        .string()
        .required("Please select a time.")
        .test("start-hour-greater", SAME_DATE_ERROR, async (value, context) => {
            const startDate = context.parent.startDate;
            const endDate = context.parent.endDate;
            const startDateTime = createCetDateObject(startDate, value);
            const endDateTime = createCetDateObject(endDate, context.parent.endHour);

            return !(formatDateNoUTC(startDateTime, "HH") >
                formatDateNoUTC(endDateTime, "HH") && startDate === endDate);
        }),
    endHour: yup
        .string()
        .required("Please select a time.")
        .test(
            "same-time-hour",
            ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE,
            async (value, context) => {
                const {path, parent, createError} = context;
                const startDate = parent.startDate;
                const endDate = parent.endDate;
                const startDateTime = createCetDateObject(startDate, parent.startHour);
                const endDateTime = createCetDateObject(endDate, value);

                return timeValidationForIndustrialOfftake(
                    JSON.parse(parent.isFlexibleDemand),
                    startDate,
                    endDate,
                    startDateTime,
                    endDateTime
                )
                    ? createError({
                        path,
                        message: ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE,
                    })
                    : {};
            }
        )
        .test(
            "same-time-hour",
            ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE,
            async (value, context) => {
                const {path, parent, createError} = context;
                const startDate = parent.startDate;
                const endDate = parent.endDate;
                const startDateTime = createCetDateObject(startDate, parent.startHour);
                const endDateTime = createCetDateObject(endDate, value);
                return timeValidationForIndustrialOfftakeMaxDays(
                    JSON.parse(parent.isFlexibleDemand),
                    startDateTime,
                    endDateTime
                )
                    ? createError({
                        path,
                        message: ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE,
                    })
                    : {};
            })
        .test("min-end-dates", "", function (value, context) {
            const {path, parent, createError} = context;
            const startDate = context.parent.startDate;
            const endDate = context.parent.endDate;
            const startDateTime = createCetDateObject(startDate, context.parent.startHour);
            const endDateTime = createCetDateObject(endDate, value);

            if (context.parent.startDate === "") return true;
            return timeValidationForFlexibleDemand(
                JSON.parse(parent.isFlexibleDemand),
                startDate,
                endDate,
                startDateTime,
                endDateTime
            )
                ? createError({
                    path,
                    message: ORDER_LIMIT_ERROR_TRAILER_BAY,
                })
                : {};
        })
        .test("min-end-dates", "", function (value, context) {
            const {path, parent, createError} = context;
            const startDate = parent.startDate;
            const endDate = parent.endDate;
            const startDateTime = createCetDateObject(startDate, parent.startHour);
            const endDateTime = createCetDateObject(endDate, value);

            if (context.parent.startDate === "") return true;
            return timeValidationForFlexibleDemandMaxDays(
                JSON.parse(parent.isFlexibleDemand),
                startDateTime,
                endDateTime
            )
                ? createError({
                    path,
                    message: ORDER_LIMIT_ERROR_TRAILER_BAY,
                })
                : {};
        }),
    totalAmount: yup
        .string()
        .when("isFlexibleDemand", {
            is: false,
            then: yup.number().required("Please enter amount.")
        })
        .test("not-flcap-greater-max", "", function (value) {
            const {path, parent, createError} = this;
            let perHourValue = value;
            if (parent.unit === DEMAND_UNIT_OPTIONS_VALUE.UNIT_PER_DAY) {
                perHourValue = parseInt(value / 24);
            }

            return isNotFLCapGreaterThanMaxDemand(
                JSON.parse(parent.isFlexibleDemand),
                perHourValue
            )
                ? createError({
                    path,
                    message: `You can request only up to (350 kg/h). Currently you have requested ${perHourValue} ${DEMAND_UNIT_OPTIONS_VALUE.UNIT_PER_HOUR}`,
                })
                : {};
        })
        .test(
            "not-flcap-less-min",
            REQUESTED_HYDROGEN_AMOUNT_IS_LESS,
            async (value, context) => {
                let perHourValue = value;
                if (context.parent.unit === DEMAND_UNIT_OPTIONS_VALUE.UNIT_PER_DAY) {
                    perHourValue = parseInt(value / 24);
                }
                return !isNotFLCapLessMinDemand(
                    JSON.parse(context.parent.isFlexibleDemand),
                    perHourValue
                );
            }
        )
        .test(
            "flcap-less-min",
            FLEXIBLE_DEMAND_MINIMUM_LIMIT_ERROR,
            async (value, context) => {
                return !isFLCapLessDemandML(
                    JSON.parse(context.parent.isFlexibleDemand),
                    value
                );
            }
        )
        .test("flcap-greater-max", "", function (value) {
            const {path, parent, createError} = this;
            return isFLCapGreaterThanDemandML(
                JSON.parse(parent.isFlexibleDemand),
                value
            )
                ? createError({
                    path,
                    message: `Your request should be less than (1100 kg). Currently you have requested ${value}kg`,
                })
                : {};
        }),
    entryMass: yup
        .string()
        .when("isFlexibleDemand", {
            is: true,
            then: yup.string().required("Please enter entry amount.")
        })
        .test("not-greater-zero", "", function (value) {
            const {path, parent, createError} = this;
            return parent.isFlexibleDemand &&
                parseInt(value) < 0 ? createError({
                    path,
                    message: `Entry mass cannot be negative.`,
                })
                : {};
        }),
    exitMass: yup
        .string()
        .when("isFlexibleDemand", {
            is: true,
            then: yup.string().required("Please enter exit amount.")
        })
        .test("not-greater-exit", "", function (value) {
            const {path, parent, createError} = this;
            const entryMass = parseInt(this.parent.entryMass);
            return isFLEntryMasGreaterExistMass(
                JSON.parse(parent.isFlexibleDemand),
                value, entryMass
            )
                ? createError({
                    path,
                    message: `Exit mass should be 100 kg more than entry mass.`,
                })
                : {};
        }),

    unit: yup.string(),
    purchaseOrderNumber: yup
        .string()
        .notRequired()
        .max(50, 'Purchase order number should be less than 50 characters'),
});
