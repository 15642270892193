import React, {useState} from "react";
import styles from "./flexibledemanddetails.module.css";
import edit from "../../../assets/Icons/Edit.svg";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import deleteIcon from "../../../assets/Icons/Delete.svg";
import truckIcon from "../../../assets/Icons/Union.svg";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    calculateDuration,
    createCetDateObject,
    formatDateNoUTC,
} from "../../../utils/date-utils";
import {
    ORDER_PAGE
} from "../../../utils/RoutesConstants";
import {
    deleteOrderFromFlexibleDemand,
    getFlexibleEditOrderDetails,
    setFlexibleDemandFlag,
    clearEditFlexibleDemand,
    clickedIndex,
} from "../../../store/actions";
import {
    DEFAULT_DATE_TIME_FORMAT,
    FLEXIBLE,
    START_DATE_PLACEHOLDER,
    START_HOUR_PLACEHOLDER
} from "../../Order/constants";
import {Box} from "@mui/material";
import PropTypes from 'prop-types';
import {formatValueWithUnit} from "../../../utils/utils";
import {UNITS} from "../../../utils/Constants";

const FlexibleDemandDetails = ({showAll}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const {flexibleDemand: flexible, editOrderId: orderId} = useSelector(
        (state) => state.orderReducer
    );

    const handleOpen = (value) => {
        setIndex(value);
        setOpen(true);
    };

    const handleDelete = () => {
        setOpen(false);
        const res = flexible.filter((itm, i) => i === index)[0];
        dispatch(clickedIndex(index));
        dispatch(clearEditFlexibleDemand());
        dispatch(deleteOrderFromFlexibleDemand(res));
    };

    const handleEdit = (index) => {
        const res = flexible.filter((itm, i) => i === index)[0];
        dispatch(clickedIndex(index));
        dispatch(
            getFlexibleEditOrderDetails({
                ...res,
                orderId: "",
            })
        );
        dispatch(setFlexibleDemandFlag(true));
        navigate(ORDER_PAGE.ORDER_TYPE_FLEXIBLE);
    };
    return (
        <>
            {flexible.map((demand, index) => {
                const startDateTime = createCetDateObject(demand.startDate, demand.startHour)
                const endDateTime = createCetDateObject(demand.endDate, demand.endHour)

                return (
                    <div
                        className={`${
                            showAll
                                ? `${styles.moreDemand} ${styles.moreDemandShowAll}`
                                : `${styles.moreDemand}`
                        }`}
                        key={demand.id}
                    >
                        <div className={styles.moreDemandLeft}>
              <span>
                <img src={truckIcon} alt="truck"/>
              </span>
                        </div>
                        <div className={styles.moreDemandRight}>
                            <div
                                className={`${
                                    showAll
                                        ? `${styles.moreDemandTop} ${styles.moreDemandTopAll}`
                                        : `${styles.moreDemandTop}`
                                }`}
                            >
                                <div>Type & ID</div>
                                <div>Order size</div>
                                <div>{showAll ? FLEXIBLE.AVAILABLE : FLEXIBLE.ARRIVAL}</div>
                                {showAll && (
                                    <>
                                        <hr className={styles.line}/>
                                        <div className={styles.toHidden}>{START_DATE_PLACEHOLDER}</div>
                                        <div>{START_HOUR_PLACEHOLDER}</div>
                                        <div>{FLEXIBLE.END_TIME_PLACEHOLDER}</div>
                                    </>
                                )}
                            </div>
                            <div
                                className={`${
                                    showAll
                                        ? `${styles.moreDemandMiddle} ${styles.moreDemandMiddleAll}`
                                        : `${styles.moreDemandMiddle}`
                                }`}
                            >
                                <div>{FLEXIBLE.FLEXIBLE_DEMAND_TYPE}, {demand.id}</div>
                                <div>{formatValueWithUnit(parseInt(demand.exitMass) - parseInt(demand.entryMass), UNITS.KG)}</div>
                                <div>{formatDateNoUTC(startDateTime)}</div>
                                {showAll && (
                                    <>
                                        <div className={styles.toHidden}>
                                            {formatDateNoUTC(startDateTime, DEFAULT_DATE_TIME_FORMAT)}
                                        </div>
                                        <div className={styles.startTimeBold}>
                                            {formatDateNoUTC(startDateTime)}
                                        </div>
                                        <div className={styles.startTimeBold}>
                                            {formatDateNoUTC(endDateTime)}{" "}
                                            <small>
                                                ({calculateDuration(startDateTime, endDateTime)})
                                            </small>
                                        </div>
                                    </>
                                )}
                            </div>
                            {orderId === "" && (
                                <div className={styles.moreDemandBottom}>
                  <span>
                    <Box onClick={() => handleEdit(index)}>
                    <img
                        src={edit}
                        alt="editIcon"
                    />
                    </Box>
                  </span>
                                    <span>
                  <Box onClick={() => handleOpen(index)}>
                    <img
                        src={deleteIcon}
                        alt="deleteIcon"
                    />
                    </Box>
                  </span>
                                </div>
                            )}
                        </div>
                        <ConfirmationModal
                            heading={FLEXIBLE.CONFIRM_MODAL.HEADER}
                            open={open}
                            handleClose={handleClose}
                            handleAction={handleDelete}
                            textToShow={FLEXIBLE.CONFIRM_MODAL.MESSAGE}
                            firstBtnText={FLEXIBLE.CONFIRM_MODAL.CANCEL_BTN}
                            secondBtnText={FLEXIBLE.CONFIRM_MODAL.OK_BTN}
                        />
                    </div>
                );
            })}
        </>
    );
};
FlexibleDemandDetails.propTypes = {
    showAll: PropTypes.any,
};
export default FlexibleDemandDetails;
